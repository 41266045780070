import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from 'vue-meta-info';

// 导入axios
import axios from "axios"

// 引入ElementPlus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// 引入第三方图标库
import 'font-awesome/css/font-awesome.min.css'

// 引入swiper
import 'swiper/swiper-bundle.css'

// 引入wow
import wow from 'wow.js'
import 'wow.js/css/libs/animate.css'


// 引入全局css
import "@/assets/css/public.css"
import "@/assets/css/style.css"


//全局引入变量
import global from './utils/global.js'

const app = createApp(App)
app.config.globalProperties.$wow = wow
app.config.globalProperties.$global = global
app.config.globalProperties.$axios = axios
app.use(ElementPlus)
app.use(store).use(router).mount('#app')