const imgUrl = 'https://editor.wormholesapp.com/'
const toUrl = 'https://view.wormholesapp.com/#/panoview/'
const bathUtl = 'https://wormholesapp.com/'
    //const apiURL = 'https://cdviews.com/' //prod
const apiURL = 'https://vrarabc.com/' //prod
    //const apiURL = 'http://localhost:9999/' //dev
const searchURL = 'https://wormholesapp.com/#/SearchDetail?caseId=1&keyword='
const loginURL = 'https://editor.wormholesapp.com/user/login'
const pageSizes = [16, 32, 64]
const pageInfo = { page: 1, size: 16 }
const bilibili = 'https://www.bilibili.com/video/BV1m14y1N7iG/?spm_id_from=333.999.0.0&vd_source=b27057d406e6f3760ceea57a9d340903'
const innerUrl = 'http://cdviews.com/video/'
const baseInnerUrl = 'http://cdviews.com/'
const baiduPano = 'https://view.wormholesapp.com/#/panoview/50033'
export default {
    imgUrl,
    toUrl,
    bathUtl,
    apiURL,
    searchURL,
    loginURL,
    pageSizes,
    pageInfo,
    bilibili,
    innerUrl,
    baseInnerUrl,
    baiduPano
}