<template>
	<div class="form_tc">		
		<el-dialog title="弹窗" v-model="detailVisible.value" center :close-on-click-modal="true" width="700px" @close="close">
			<div class="w100 textcenter colorfff">
				<h1 class="titleh1 coloryellow">{{tname}}</h1>
				<!--<p class="text18 mt_10">了解更多VR全景解决方案</p>-->
				<div class="fmbox mt_25">
					<div class="fmboxa posrelative">
						<i><img src="@/assets/images/formico1_03.png" /></i>
						<input class="text16" type="text" placeholder="您的姓名" v-model="leave.name">
					</div>
					<div class="fmboxa posrelative">
						<i><img src="@/assets/images/formico2_03.png" /></i>
						<input class="text16" type="text" placeholder="您的电话" v-model="leave.tel">
					</div>
					<div class="fmboxa posrelative" v-if="t==='left'">
						<i><img src="@/assets/images/lv2ico7_03.png" /></i>
						<input class="text16" type="text" placeholder="留言" v-model="leave.contents">
					</div>
					<div class="fmboxa">
						<button class="bgblue text20 cup hoverop" v-if="t==='left'" @click="save()">在线留言</button>
						<button class="bgblue text20 cup hoverop" v-if="t!=='left' && t!=='join'"  @click="save()">免费领取</button>
						<button class="bgblue text20 cup hoverop" v-if="t==='join'"  @click="save()">快速加盟</button>
					</div>
					<p class="text16 mt_15">已有 <i class="colorblue">{{leave.counts}}</i> 位客户{{tname}}</p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import global from '@/utils/global'
import axios from "axios"
import { ElMessage } from 'element-plus'
	export default{
		name: "Form",
		data(){
			return{
					t:'',
			    tname:'',
					detailVisible:{
						value:false
					},
					leave:{
						id:'',
						type:'',
						tname:'',
						name:'',
						contents:'',
						tel:'',
						counts:2112
					}
			}
		},
		created() {
			this.getMaxCounts()
		},
		mounted(){
			
		},
		methods:{
		 initForm(t,tname){
				this.detailVisible.value=true
				this.t=t
				this.leave.type=t
				this.tname=tname
				this.leave.tname=tname
		 },
		 close(){
		  	this.detailVisible.value=false
				this.initLeave()
		 },
		 initLeave(){
		   	this.leave.id=''
				this.leave.type='',
				this.leave.name='',
				this.leave.contents='',
				this.leave.tel=''
		 }
		 ,
		 getMaxCounts(){
				axios({
									url:global.apiURL+'portal/getMaxCounts',
									method:'get'
							}).then((result) => {
							    this.leave.counts=this.market=result.data.data.counts
							}).catch((err)=>{
								 this.leave.counts=2112
							})
		 },
		 save(){
			if(!this.leave.tel || !this.leave.name){
					ElMessage({
														message: '提示, 手机号与姓名不能为空',
														type: 'warning',
									})
					return
			}
		  this.leave.counts=this.leave.counts+1
			  axios({
								url:global.apiURL+'portal/insert',
								method:'post',
								data:this.leave
						}).then((result) => {
							if(result.data.data){
								this.detailVisible.value=false
								this.initLeave()
								ElMessage({
									message: '谢谢您, 我们会尽快联系您！',
									type: 'success',
								})
							}
						}).catch((err)=>{
								ElMessage({
									message: '谢谢您, 系统未知错误！',
									type: 'danger',
								})
						})
		 }
		}
	}
</script>

<style>
	.form_tc .el-dialog__title{
		display: none;
	}
	.form_tc .el-overlay-dialog{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.form_tc .el-dialog{
		background: url('@/assets/images/formbg_03.jpg') no-repeat center #282828;
		background-size: cover;
		margin: 0 auto;
		margin-top: 0 !important;
	}
	.form_tc .el-dialog__body{
		padding-top: 10px;
		padding-bottom: 50px;
	}
	.form_tc .el-dialog__headerbtn .el-dialog__close{
		color: #fff;
		font-size: 30px;
	}
	.fmbox{
		width: 360px;
		margin: 0 auto;
		margin-top: 35px;
	}
	.fmboxa i{
		width: 50px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
	}
	.fmboxa{
		margin-top: 20px;
	}
	.fmboxa input{
		width: 100%;
		height: 60px;
		border: 1px solid rgba(255,255,255,.37);
		background: none;
		color: #fff;
		padding-left: 50px;
		padding-right: 10px;
	}
	.fmboxa input::-webkit-input-placeholder{
		color:#ddd;
	}
	.fmboxa button{
		height: 60px;
		width: 100%;
		border-radius: 4px;
		transition: .25s;
	}
	
	@media only screen and (max-width:768px) {
		.form_tc .el-dialog{
			width: 85% !important;
		}
		.fmbox{
			width: 100%;
			margin-top: 0;
		}
		.fmboxa input{
			height: 40px;
			padding-left: 40px;
		}
		.fmboxa button{
			height: 40px;
		}
		.fmboxa i{
			width: 40px;
		}
		.fmboxa i img{
			width: 16px;
		}
		.form_tc .el-dialog__body{
			padding-bottom: 20px;
		}
		.form_tc .el-dialog__headerbtn{
			top:10px;
			right: 10px;
		}
	}
</style>