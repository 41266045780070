<template>
	<div>
		<div class="foot">
			<div class="ft">
				<div class="wrap_1400 disflex flex_lmr wap_disblock">
					<div class="ftl ftlactive">
						<h3 class="text20 ftwt_none">联系我们</h3>
						<div class="ftlline"></div>
						<ul class="ftlb text14 lht30">
							<li><i class="fa fa-map-marker"></i>地址:深圳龙华区青创园</li>
							<li><i class="fa fa-phone"></i>服务电话:18682036600</li>
							<li><i class="fa fa-envelope-o" style="font-size: 12px;"></i>邮箱:vip@wormholesapp.com</li>
							<li><i class="fa fa-qq" style="font-size: 13px;"></i>QQ:125107037</li>
							<li><i class="fa fa-user"></i>商务/彭总：13560772708</li>
							<li><i class="fa fa-user"></i>合作/杨总：18682036600</li>
						</ul>
					</div>
					<div class="ftl">
						<h3 class="text20 ftwt_none">VR全景</h3>
						<div class="ftlline"></div>
						<ul class="ftlb text14 lht30">
							<li><a href="http://wormholesapp.com/#/author" target="_blank">摄影师</a></li>
							<li><a href="http://wormholesapp.com/#/panomarket"  target="_blank">正版素材</a></li>
							<li><a href="http://wormholesapp.com/#/news"  target="_blank">VR知识库</a></li>
							<li><router-link to="/videoCenter">视频中心</router-link></li>
							<li><router-link to="/case">VR全景案例</router-link></li>
							<li><router-link to="/live">VR全景直播</router-link></li>
							<li><a href="https://wormholesapp.com/#/panomap" target="_blank">全景地图</a></li>
						</ul>
					</div>
					<div class="ftl">
						<h3 class="text20 ftwt_none">关于虫洞全景</h3>
						<div class="ftlline"></div>
						<ul class="ftlb text14 lht30">
							<li><a href="/aboutCulture">企业文化</a></li>
							<li><a href="join">加盟创业</a></li>
							<li><a href="/aboutCourse">发展历程</a></li>
							<li><a href="">在线留言</a></li>
							<li><a href="/about">关于我们</a></li>
							<li><a href="/aboutContact">联系我们</a></li>
						</ul>
					</div>
					<div class="ftl">
						<h3 class="text20 ftwt_none">解决方案</h3>
						<div class="ftlline"></div>
						<ul class="ftlb text14 lht30">
							<li><a href="/solution?type=vr&tagName=all">VR全景行业</a></li>
							<li><a href="/solution?type=school&tagName=院校展馆">院校解决方案</a></li>
							<li><a href="/solution?type=step&tagName=step">步进式漫游</a></li>
							<li><a href="/solution?type=live&tagName=live">VR视频与直播</a></li>
							<li><a href="/solution?type=travel&tagName=景点旅游">旅游行业</a></li>
							<li><a href="/solution?type=hotel&tagName=酒店住宿">酒店行业</a></li>
							<li><a href="/solution?type=food&tagName=城市商业">餐饮行业</a></li>
							<li><a href="/solution?type=religion&tagName=religion">宗教解决方案</a></li>
						</ul>
					</div>
					<div class="ftr">
						<h3 class="text20 ftwt_none">关注我们</h3>
						<div class="ftlline"></div>
						<div class="ftrw textcenter disflex flex_wrap flex_lmr">
							<div class="ftra">
								<img src="../../assets/images/ewm1_03.png" />
								<p>扫一扫关注公众号</p>
							</div>
							<div class="ftra">
								<img src="../../assets/images/ewm2_03.png" />
								<p>微信客服</p>
							</div>
							<div class="ftra">
								<img src="../../assets/images/ewm3_03.png" />
								<p>微信小程序</p>
							</div>
							<div class="ftra">
								<img src="../../assets/images/ewm4_03.jpg" />
								<p>抖音小程序</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fb text14 textcenter">
				<div class="wrap_1400">
					<p>Copyright @ 深圳虫洞全景科技有限公司 2015-2023 All Rights Reserved <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022101947号-3</a>  <a href="">粤公网安备 44030902003581号</a></p>
					<p>本站法律顾问 广东律瀛律师事务所 李律师</p>
					<p>为推动防范电信网络诈骗工作，如接到962110电话，请立即接听 </p>
				</div>
			</div>
		</div>
		<!-- 右侧 -->
		<div class="right">
			<div class="rta posrelative">
				<a href="javascript:void(0)" class="rta_a" @click="handleForm('left','给我们留言')"><i class="fa fa-commenting-o"></i><p>留言</p></a>
			</div>
			<div class="rta posrelative">
				<a href="javascript:void(0)" class="rta_a"><i class="fa fa-weixin"></i><p>微信</p></a>
				<div class="rtahide rtahide_ewm">
					<img class="w100 disblock" src="../../assets/images/weixin.jpg">
					<p class="mt_5 text12 textcenter">微信公众号</p>
				</div>
			</div>
				<div class="rta posrelative">
				<a href="javascript:void(0)" class="rta_a"><i class="fa fa-weixin"></i><p>抖音</p></a>
				<div class="rtahide rtahide_ewm">
					<img class="w100 disblock" src="../../assets/images/douyinanli.jpg">
					<p class="mt_5 text12 textcenter">抖音</p>
				</div>
			</div>
			<div class="rta posrelative">
				<a href="tel:18682036600" class="rta_a"><i class="fa fa-phone"></i><p>电话</p></a>
				<div class="rtahide rtahide_phone">
					18682036600
				</div>
			</div>
			<div class="rta posrelative">
				<a href="javascript:void(0)" class="rta_a" @click="backTop()"><i class="fa fa-angle-up"></i><p>回顶部</p></a>
			</div>
		</div>
	</div>
	<Form ref="isShowForm"></Form>
</template>

<script>
import { ref } from "vue";
import Form from '@/components/popup/Form.vue'
export default{
	components: {
		Form
	},
	setup(){
		// 充值弹窗
		const isShowForm = ref(false)  //isShowForm 要和Form组件上的class名相同
		const handleForm = (t,tname)=> {
			isShowForm.value.initForm(t,tname); //initForm是子组件里的方法
		}
		return{
			isShowForm,
			handleForm
		}
	},
	methods:{
		//返回头部
		backTop() {
		 let top = document.documentElement.scrollTop || document.body.scrollTop
		 // 实现滚动效果
		 const timeTop = setInterval(() => {
		   document.body.scrollTop = document.documentElement.scrollTop = top -= 50
		   if (top <= 0) {
		     clearInterval(timeTop)
		   }
		 }, 10)
		},
	}
}
</script>

<style scoped>
.foot{
	background: #222222;
	color: #fff;
}
.foot a{
	color: #fff;
}
.ft{
	padding: 35px 0;
	background: url('~@/assets/images/footbg_02.png') no-repeat center #282828;
	background-size: 100%;
}
.ftlline{
	width: 28px;
	height: 3px;
	background: #1eadef;
	display: block;
	margin: 15px 0;
}
.ftlb li i{
	color: #1eadef;
	margin-right: 5px;
	width: 20px;
	text-align: center;
}
.ftlb li a:hover{
	color:#1eadef;
}
.ftr{
	width: 250px;
}
.ftra{
	width: 45%;
	margin-bottom: 10px;
}
.ftra img{
	width: 100%;
	display: block;
	margin-bottom: 5px;
}
.fb{
	padding: 15px 0;
}
.right{
	position: fixed;
	right: 0;
	bottom: 15%;
	z-index: 99;
	border: 1px solid #e6e6e6;
	background: #fff;
}
.rta_a{
	padding: 5px 0 5px 0;
	text-align: center;
	width: 45px;
	display: block;
	font-size: 12px;
	border-bottom: 1px solid #e6e6e6;
	transition: .25s;
}
.rta:hover .rta_a{
	background: #1eadef;
	color: #fff;
}
.rta_a i{
	font-size: 14px;
}
.rta:last-child .rta_a{
	border: 0;
}
.rta_a p{
	margin-top: 3px;
}
.rtahide{
	position: absolute;
	right: 0;
	height: 100%;
	top: 0;
	z-index: 8;
	background: #1eadef;
	color: #fff;
	opacity: 0;
	visibility: hidden;
}
.rta:hover .rtahide{
	opacity: 1;
	visibility: inherit;
	right: 100%;
	margin-right: 15px;
	transition: .5s;
}
.rtahide_phone{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	border-radius: 6px;
}
.rtahide::after{
	width: 10px;
	height: 10px;
	background-color: #1eadef;
	content: "";
	position: absolute;
	right: -5px;
	top: 17px;
	transform:rotate(45deg);
}
.rtahide_ewm{
	width: 120px;
	padding: 10px;
	height: auto;
}
@media only screen and (max-width:1399px) {
	.ftr{
		width: 200px;
	}
	.ftrw p{
		font-size: 12px;
	}
}
@media only screen and (min-width:768px) and (max-width:1199px) {
	.ftlline{
		width: 20px;
		height: 2px;
		margin: 10px 0;
	}
}
@media only screen and (max-width:768px) {
	.ft{
		padding: 22px 0;
	}
	.ftl{
		display: none;
	}
	.ftlactive{
		display: block;
		text-align: left;
		width: 220px;
		margin: 0 auto;
	}
	.ftlactive .ftlline{
		margin-left: 0;
	}
	.ftlb{
		font-size: 12px;
	}
	.ftlb li i{
		font-size: 12px !important;
	}
	.ftlline{
		width: 15px;
		height: 1px;
		margin: 10px auto 10px 0;
	}
	.ftr{
		margin: 0 auto;
		margin-top: 15px;
		width: 220px;
	}
	.ftrw {
		margin-top: 20px;
	}
	.fb{
		font-size: 12px;
		line-height: 23px;
	}
}
</style>