<template>
	<div class="app-container">
		<Header :activeName="activeName"></Header>
		<!-- 幻灯
		<div class="bnbox iframeOnly">
			<div class="VideoPlayer posrelative">
				<video src="@/assets/images/banner.mp4" width="100%" autoplay loop muted class="videoplayer"></video>
			</div>
		</div> 
		-->
		<div class="listbn posrelative">
  				<iframe  src="https://view.wormholesapp.com/#/panoview/50006" frameborder ="no" border="0" class="disblock w100 iframeOnly iframeBanner"></iframe>
  		<div class="posrabsolute disflex flex_lr_center flex_center lbntxt1 colorfff">
  			<div class="wrap_1400 textcenter">
					<h1  style="color:rgba(255,0,0,0.6)">八 年 视 频 拍 摄 与 V R 全 景 行 业 经 验</h1>
					<h1 class="titleh2 ftwt_none"  style="color:rgba(255,1,1,0.8)">VR全景、毕业季、活动拍摄、政府旧改项目、企业宣传片、产品广告、3D动画、MV拍摄、中视频拍摄</h1>
					<div class="lbntxt_live_a mt_40 disflex flex_lr_center text20">
						<router-link to="/case">VR全景案例</router-link>
						<a href="https://space.bilibili.com/3493116243872709/channel/series" class="active" target="_blank"><img class="middle mr5" src="../../assets/images/spico_03.png">视频案例</a>
					</div>
			</div>
  		</div>
  	    </div>
		<div class="it">
			<div class="wrap_1400 disflex flex_lmr flex_center wap_disblock">
				<div class="itl disflex flex_center flex_wrap flex1">
					<a href="javascript:void(0)">VR全景漫游系统</a>
					<a href="javascript:void(0)">VR全景拍摄</a>
					<a href="javascript:void(0)">后期制作</a>
					<a href="javascript:void(0)">短视频，宣传片</a>
					<a href="javascript:void(0)">VR全景+送营销小程序+免费搭建公众号</a>
					<a href="javascript:void(0)">高德，美团，携程，飞猪各大平台的VR全景展示</a>
					<a href="javascript:void(0)">360，高德，腾讯地图标注，为VR全景展示服务</a>
				</div>
			</div>
		</div>
		<div class="i1 md">
			<div class="wrap_1400 textcenter disflex flex_lmr flex_wrap">
				<div class="i1a wow fadeInLeft">
					<div class="i1at disflex flex_lr_center flex_end text18 colorblue">
						<h1>
							<countTo :end='8' :autoPlay="true" :duration='5000' />
						</h1>
						<span class="ml5">年</span>
					</div>
					<p class="text18 mt_10">VR领域深耕</p>
				</div>
				<div class="i1a wow fadeInLeft" data-wow-delay=".2s">
					<div class="i1at disflex flex_lr_center flex_end text18 colorblue">
						<h1>
							<countTo :end='50' :autoPlay="true" :duration='5000' />
						</h1>
						<span class="ml5">种</span>
					</div>
					<p class="text18 mt_10">行业服务经验</p>
				</div>
				<div class="i1a wow fadeInLeft" data-wow-delay=".4s">
					<div class="i1at disflex flex_lr_center flex_end text18 colorblue">
						<h1>
							<countTo :end='300' :autoPlay="true" :duration='5000' />
						</h1>
						<span class="ml5">个</span>
					</div>
					<p class="text18 mt_10">落地服务城市</p>
				</div>
				<div class="i1a wow fadeInLeft" data-wow-delay=".8s">
					<div class="i1at disflex flex_lr_center flex_end text18 colorblue">
						<h1>
							<countTo :end='1000' :autoPlay="true" :duration='5000' />
						</h1>
						<span class="ml5">个</span>
					</div>
					<p class="text18 mt_10">产品特色功能</p>
				</div>
				<div class="i1a wow fadeInLeft" data-wow-delay="1s">
					<div class="i1at disflex flex_lr_center flex_end text18 colorblue">
						<h1>
							<countTo :end='3000' :autoPlay="true" :duration='5000' />
						</h1>
						<span class="ml5">个</span>
					</div>
					<p class="text18 mt_10">终端商业项目</p>
				</div>
			</div>
		</div>
		<div class="i2 md">
			<div class="wrap_1400">
				<div class="itop textcenter wow fadeInUp">
					<h1 class="titleh1 color000">虫洞全景可以上传到如下地图平台，提高企业全网曝光度</h1>
				</div>
				<div class="i2w disflex flex_wrap mt_25 wap_mt15 wow fadeInUp">
					<a href="javascript:void(0)" class="i2a hvimghover wap_mt15"><img class="img"
							src="@/assets/images/i2logo1_03.png" /></a>
					<a href="javascript:void(0)" class="i2a hvimghover wap_mt15"><img class="img"
							src="@/assets/images/i2logo2_03.png" /></a>
					<a href="javascript:void(0)" class="i2a hvimghover wap_mt15"><img class="img"
							src="@/assets/images/i2logo3_03.png" /></a>
					<a href="javascript:void(0)" class="i2a hvimghover wap_mt15"><img class="img"
							src="@/assets/images/i2logo4_03.png" /></a>
					<a href="javascript:void(0)" class="i2a hvimghover wap_mt15"><img class="img"
							src="@/assets/images/i2logo5_03.png" /></a>
					<a href="javascript:void(0)" class="i2a hvimghover wap_mt15"><img class="img"
							src="@/assets/images/i2logo6_03.png" /></a>
				</div>
			</div>
		</div>
		<div class="i3 md">
			<div class="wrap_1400">
				<div class="itop textcenter wow fadeInUp">
					<h1 class="titleh1 color000">行业解决方案</h1>
				</div>
				<div class="i3w i3w_div disflex flex_wrap mt_25 wap_mt15 textcenter wow fadeInUp">
					<a :href="solutionURL+item.type+solutionURLParamers+item.anliParams" class="i3a hvimghover wap_mt15" v-for="item in solutionMV" :key="item.id">
						<div class="ovhd"><img class="disblock w100 img" :src="baseInnerUrl+item.homeImgURL" /></div>
						<h3 class="text18 mt_10 textcenter">{{item.solutionName}}</h3>
						<p class="text14 mt_5">{{item.homeSolutionContents}}</p>
						<span class="mt_15">查看案例</span>
					</a>
				</div>
				<div class="textcenter mt_50 wow fadeInUp">
					<a href="javascript:void(0)" class="morea bgyellow text18" @click="handleForm('solution','免费领取商业解决方案')">免费领取商业解决方案</a>
				</div>
			</div>
		</div>
		<div class="i4 md">
			<div class="wrap_1400">
				<div class="itop textcenter wow fadeInUp">
					<h1 class="titleh1 color000">虫洞VR全景平台功能展示</h1>
					<h3 class="text20 color000 mt_10">丰富的营销插件</h3>
				</div>
				<div class="i4w mt_10 disflex flex_wrap wow fadeInUp">
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico1_03.png" /></i>
						<h3 class="text18">微名片</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico2_03.png" /></i>
						<h3 class="text18">在线预约</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico3_03.png" /></i>
						<h3 class="text18">微留言</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico4_03.png" /></i>
						<h3 class="text18">微房产</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico5_03.png" /></i>
						<h3 class="text18">微酒店</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico6_03.png" /></i>
						<h3 class="text18">微喜帖</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico7_03.png" /></i>
						<h3 class="text18">微餐饮</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico8_03.png" /></i>
						<h3 class="text18">KTV</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico9_03.png" /></i>
						<h3 class="text18">微邀请</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico10_03.png" /></i>
						<h3 class="text18">拼多多</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico11_03.png" /></i>
						<h3 class="text18">微投票</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico12_03.png" /></i>
						<h3 class="text18">问卷调查</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico13_03.png" /></i>
						<h3 class="text18">微官网</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico14_03.png" /></i>
						<h3 class="text18">微相册</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico15_03.png" /></i>
						<h3 class="text18">微论坛</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico16_03.png" /></i>
						<h3 class="text18">微招聘</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico17_03.png" /></i>
						<h3 class="text18">微汽车</h3>
					</a>
					<a href="javascript:void(0)" class="i4a wap_mt15">
						<i><img src="@/assets/images/i4ico18_03.png" /></i>
						<h3 class="text18">微卡券</h3>
					</a>
				</div>
				<div class="disflex flex_center flex_lr_center mt_50 wow fadeInUp">
					<h3 class="titleh3">更多VR全景功能营销插件</h3>
					<a href="javascript:void(0)" class="i4morea text18" @click="handleForm('plugs','免费领取资料')">免费领取资料</a>
				</div>
			</div>
		</div>
		<div class="i5 md">
			<div class="wrap_1400">
				<div class="itop textcenter wow fadeInUp">
					<h1 class="titleh1 color000">虫洞VR全景平台功能展示</h1>
					<h3 class="text20 color000 mt_10">丰富的交互功能</h3>
				</div>
				<div class="i5w mt_25 wap_mt15 disflex flex_wrap wow fadeInUp">
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic1_03.jpg" />
						</div>
						<h3 class="i5atxt text18">红包</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic2_03.jpg" />
						</div>
						<h3 class="i5atxt text18">场景付费</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic3_03.jpg" />
						</div>
						<h3 class="i5atxt text18">作品打赏</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic4_03.jpg" />
						</div>
						<h3 class="i5atxt text18">视频开场提示</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic5_03.jpg" />
						</div>
						<h3 class="i5atxt text18">场景标注</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic6_03.jpg" />
						</div>
						<h3 class="i5atxt text18">小程序管理</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic7_03.jpg" />
						</div>
						<h3 class="i5atxt text18">广告模块</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic8_03.jpg" />
						</div>
						<h3 class="i5atxt text18">在线约拍</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic9_03.jpg" />
						</div>
						<h3 class="i5atxt text18">全景在线商城</h3>
					</a>
					<a href="" class="i5a hvimghover">
						<div class="ovhd"><img class="disblock w100 img" src="@/assets/images/i5pic10_03.jpg" />
						</div>
						<h3 class="i5atxt text18">数据统计</h3>
					</a>
				</div>
			</div>
		</div>
		<div class="i6 md">
			<div class="wrap_1400">
				<div class="textcenter disflex flex_lr_center flex_center wap_disblock wow fadeInUp">
					<div class="itop textcenter">
						<h1 class="titleh1 color000">热门行业VR全景展示</h1>
					</div>
					<a href="javascript:void(0)" @click="toPublish('anli')" class="itop_fbzp text16 wap_mt15">发布作品</a>
					<div class="itr disflex wap_mt15" style="margin-left:10px">
								<input placeholder="案例关键词搜索" v-model="key">
								<button @click="btnSearch">搜索</button>
					</div>
				</div>
				<div class="i6t i6t_a disflex flex_wrap text16 mt_25 wap_mt15 wow fadeInUp">
					<span @click="i6SpanClick('recommend',0)" :class="{active:ins==0}">热门推荐</span>
					<span @click="i6SpanClick(item.tagName,item.tagId)" :class="{active:ins==item.tagId}" v-for="item in hotTag" :key="item.tagId">{{item.tagName}}</span>
				</div>
				<div class="i6w disflex flex_wrap mt_15 textcenter wow fadeInUp">
					<a :href="toUrl+item.panoId" class="i6a hvimghover" v-for="item in ali" :key="item.id" target="_blank">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" :src="imgUrl+item.iconSrc" />
							<span>{{item.tagId}}</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">{{item.panoName}}</h3>
							<span class="color666">{{item.nickName}}</span>
						</div>
					</a>
					<el-tag v-if="isShow" type="danger">暂无数据</el-tag>
					<!--
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/lv4pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>
					<a href="" class="i6a hvimghover">
						<div class="i6aimg i6aimg_div ovhd posrelative">
							<img class="disblock w100 img" src="@/assets/images/i6pic_03.jpg" />
							<span>旅游 城市 航拍</span>
						</div>
						<div class="i6atxt disflex flex_center mt_10">
							<h3 class="text16 line_1 flex1 mr15">虫洞全景-深圳全景</h3>
							<span class="color666">虫洞VR</span>
						</div>
					</a>-->
				</div>
				<div class="textcenter mt_50 wow fadeInUp">
					<router-link to="/case" class="morea bgyellow text18">查看更多案例</router-link>
				</div>
			</div>
		</div>
		<div class="i7 md pb0">
			<div class="wrap_1400">
				<div class="textcenter disflex flex_lr_center flex_center wap_disblock wow fadeInUp">
					<div class="itop textcenter">
						<h1 class="titleh1 color000">视频案例</h1>
					</div>
					<router-link to="/case" class="itop_fbzp text16 wap_mt15">更多视频</router-link>
				</div>
				<h3 class="text20 color000 mt_10" style="text-align:center">200多部宣传片、商业活动、企业微电影、VR视频等作品</h3>
			</div>
			<div class="i7w w100 posrelative mt_35 wow fadeInUp">
				<swiper
				:navigation="true" 
				:loop="true"
				:speed="800"
				:centeredSlides="true"
				:breakpoints='{
					768: {
						slidesPerView: 1.5,
						spaceBetween: 0,
					},
					950: {
						slidesPerView: 2,
						spaceBetween: 0,
					},
					1200: {
						slidesPerView: 2.1,
						spaceBetween: 0,
					},
				}' class="mySwiper">
					<swiper-slide>
						<div class="i7a posrelative" @click="i7aVideo('0011.mp4')">
							<img class="disblock w100" src="@/assets/video/0011.png" />
							<div class="i7a_vd posrabsolute disflex flex_center flex_lr_center">
								<div class="w100 textcenter colorfff">
									<div class="titleh3">
										<h1><i class="fa fa-play"></i></h1>
									</div>
									<h3 class="titleh3 ftwt_none mt_15">VR产业化</h3>
								</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="i7a posrelative" @click="i7aVideo('0010.mp4')">
							<img class="disblock w100" src="@/assets/video/0010.png" />
							<div class="i7a_vd posrabsolute disflex flex_center flex_lr_center">
								<div class="w100 textcenter colorfff">
									<div class="titleh3">
										<h1><i class="fa fa-play"></i></h1>
									</div>
									<h3 class="titleh3 ftwt_none mt_15">2022国剧盛典VR直播</h3>
								</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="i7a posrelative" @click="i7aVideo('0012.mp4')">
							<img class="disblock w100" src="@/assets/video/0012.png" />
							<div class="i7a_vd posrabsolute disflex flex_center flex_lr_center">
								<div class="w100 textcenter colorfff">
									<div class="titleh3">
										<h1><i class="fa fa-play"></i></h1>
									</div>
									<h3 class="titleh3 ftwt_none mt_15">什么是VR全景</h3>
								</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="i7a posrelative" @click="i7aVideo('0001.mp4')">
							<img class="disblock w100" src="@/assets/video/0001.png" />
							<div class="i7a_vd posrabsolute disflex flex_center flex_lr_center">
								<div class="w100 textcenter colorfff">
									<div class="titleh3">
										<h1><i class="fa fa-play"></i></h1>
									</div>
									<h3 class="titleh3 ftwt_none mt_15">VR时空还原与虚拟人在同一个场景</h3>
								</div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
		<div class="i8 md">
			<div class="wrap_1400">
				<div class="textcenter i8w wow fadeInUp">
					<h1 class="titleh1 color000 mt_20">视频拍摄，全国咨询热线：<span class="colorblue">186-8203-6600</span></h1>
					<div class="textcenter mt_20">
						<a href="javascript:void(0)" class="morea bgyellow text18" @click="handleForm('quotes','获取视频报价')">立即获取报价</a>
					</div>
				</div>
			</div>
		</div>
		<div class="i9 md">
			<div class="wrap_1400">
				<div class="itop textcenter wow fadeInUp">
					<h1 class="titleh1 color000">VR全景学习</h1>
				</div>
				<div class="i9t i6t disflex flex_wrap flex_lr_center text16 mt_15 wow fadeInUp">
					<span @click="i9SpanClick(2)" :class="{active:ins2==2}">VR全景</span>
					<span @click="i9SpanClick(1)" :class="{active:ins2==1}">平台视频教程</span>
					<span @click="i9SpanClick(4)" :class="{active:ins2==4}">短视频</span>
					<span @click="i9SpanClick(5)" :class="{active:ins2==5}">VR热点新闻</span>
					<span @click="i9SpanClick(7)" :class="{active:ins2==7}">会员说明</span>
					<span @click="i9SpanClick(3)" :class="{active:ins2==3}">常见问题</span>
					<span @click="i9SpanClick(6)" :class="{active:ins2==6}">软件版本</span>
				</div>
				<div class="i9w mt_25 wap_mt15 disflex flex_lmr flex_wrap wow fadeInUp">
					<el-tag v-if="isSysNewsShow" type="danger">暂无数据</el-tag>
					<a :href="bathUtl+'#/news-detail/'+item.id" class="i9a i9a_div hvimghover disflex flex_center w49 wap_w100" v-for="item in sysNews" :key="item.id" target="_blank">
						<div class="i9al flex1">
							<h3 class="text18 line_1 ftwt_none">{{item.title}}</h3>
							<p class="text14 mt_10 line_3">
								{{vHtml(item.content).slice(0,50)}}
							</p>
							<div class="mt_5 text20"> <el-link type="warning">详情</el-link></div>
						</div>
						<div class="i9ar ovhd"><img class="disblock w100 img" :src="imgUrl+item.coverUrl" />
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="i10 md">
			<div class="wrap_1400">
				<div class="i10box wow fadeInLeft">
					<h1 class="titleh1 colorblue">ABOUT US</h1>
					<h3 class="text20">关于我们</h3>
					<div class="txt mt_20 wap_mt15 lht35">
						<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳虫洞全景科技有限公司，以下简称（虫洞全景）成立于2015年，位于广东省深圳市，主要从事金融、证券、物流、仓储、财务相关行业的软件开发服务。</p>
							<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;另外虫洞全景积极拥抱元宇宙，除了自身的软件开发服务外，在2018年加入VR、AR、MR阵云，提供VR全景漫游、VR直播，面向酒店、餐饮、KTV、学校、旅游、博物馆、画展、房地产、汽车、智慧城市等相关领域....并可以针对相关行业在美团、高德、去哪网、口碑、飞猪进行全景展示。</p>
							<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;虫洞全景还为全国摄影爱好者提一个免费的平台，他们可以在我们的平台上传全景图片，用于学习或是商业化。</p>
							<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时2018年虫洞全景成立了宣传片拍摄的业务线部门，4年来与近200家企业进行了合作，为他们制作了高质量的宣传片。</p>
					</div>
					<div class="i10more mt_25 wap_mt15">
						<router-link to="/about">了解更多 ></router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="i11 md">
			<div class="wrap_1400">
				<div class="itop textcenter wow fadeInUp">
					<h1 class="titleh1 color000">VR虫洞全景素材区块链保护与交易</h1>
				</div>
				<div class="i11box disflex wap_disblock mt_50 wow fadeInUp">
					<div class="i11l flex1 disflex textcenter flex_wrap">
							<el-tag v-if="isMarketShow" type="danger">暂无数据</el-tag>
						<div class="i11la hvimghover" v-for="item in market" :key="item.id">
							<div class="i11laimg i11la_div ovhd posrelative">
								<a :href="toUrl+item.panoId" target="_blank" class="disblock w100"><img class="disblock w100 img"
										:src="imgUrl+item.iconSrc" /></a>
								<a href="http://wormholesapp.com/#/panomarket"  class="i11laimga"><img class="disblock"
										src="@/assets/images/car_03.png" /></a>
							</div>
							<h3 class="text16 mt_10">{{item.name}}</h3>
						</div>
					</div>
					<div class="i11r wap_w100 wap_mt15 wap_ml0 text18">
						<span @click="getMarket(0)" :class="{active:ins10==0}">全 部</span>
						<span @click="getMarket(1)" :class="{active:ins10==1}">城 市</span>
						<span @click="getMarket(2)" :class="{active:ins10==2}">风 景</span>
						<span @click="getMarket(3)" :class="{active:ins10==3}">汽 车</span>
						<span @click="getMarket(4)" :class="{active:ins10==4}">建 筑</span>
						<span @click="getMarket(5)" :class="{active:ins10==5}">装 修</span>
						<span @click="getMarket(6)" :class="{active:ins10==6}">乡 村</span>
					</div>
				</div>
				<div class="textcenter mt_30 wow fadeInUp">
					<a href="https://editor.wormholesapp.com/user/login" target="_blank"
						class="morea bgyellow text18">免费上传素材</a>
				</div>
			</div>
		</div>
		<div class="i12 md pt0 mt_25">
			<div class="wrap_1400 wow fadeInUp">
				<div class="itop textcenter">
					<h1 class="titleh1 color000">虫洞全景合作伙伴</h1>
					<h3 class="text20 color000 mt_10">虫洞全景，一个旨在为客户创造价值的企业，值得信赖！</h3>
				</div>
				<div class="i12w mt_40 disflex flex_wrap">
						<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_1.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_2.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_3.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_4.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_5.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_6.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_7.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_8.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_9.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_10.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_11.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_12.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_13.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_14.png" /></a>
					<a href="javascript:void(0)" class="i12a hvimghover"><img class="img" src="@/assets/images/link_15.png" /></a>
				</div>
			</div>
		</div>

		<!-- <a href="javascript:" @click="handleForm()">会员充值</a>
		<a href="javascript:" @click="handlePay()">会员充值</a> -->
		
		<Footer />
		<!--弹窗组件-->
		<Form ref="isShowForm"></Form>
		<Video ref="isShowVideo"></Video>
	</div>
</template>

<script>
import { ref } from "vue";
//公共组件
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'
import Form from '@/components/popup/Form.vue'
import Video from '@/components/popup/Video.vue'
import global from '@/utils/global'
import {backTop} from '@/utils/fun'
// 导入axios
import axios from "axios"

//数字增长组件
import countTo from '@/components/vue-count-to/vue-countTo'

//Swiper
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperBanner, { Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper'
SwiperBanner.use([Pagination, Navigation, EffectCoverflow, Autoplay])


export default {
	name: 'Home',
	components: {
		Header,
		Footer,
		Form,
		Video,
		countTo,
		Swiper,
		SwiperSlide,
	},
	setup() {
		//导航高亮标识
		const activeName = 'home';
		
		// 表单弹窗
		const isShowForm = ref(false)  //isShowForm 要和Form组件上的class名相同
		const handleForm = (t,tname)=> {
			isShowForm.value.initForm(t,tname); //initForm是子组件里的方法
		}
		// 视频弹窗
		const isShowVideo = ref(false)  //isShowForm 要和Form组件上的class名相同
		const i7aVideo = (url)=> {
			isShowVideo.value.initVideo(global.innerUrl+url); //initForm是子组件里的方法
		}
				
		return{
			activeName,
			isShowVideo,
			i7aVideo,
			isShowForm,
			handleForm
		}
		
	},
	data() {
		return {
			key:'',
			imgUrl:global.imgUrl,
			toUrl:global.toUrl,
			bathUtl:global.bathUtl,
			baseInnerUrl:global.baseInnerUrl,
			loginURL:global.loginURL,
			number: true,			
			//切换样式
			ins: 0,
			ins1: 1,
			ins2: 2,	
			ins10:0,
			ali:[],
			sysNews:[],
			market:[],
			hotTag:[],
			//暂无数据
			isAliShow:false,
			isSysNewsShow:false,
			isMarketShow:false,
			isShow:false,
			solutionMV:[],
			solutionURL:'/solution?type=',
			solutionURLParamers:'&tagName=',
		};
	},
	created() {
		//请求anli
     this.getAnli('recommend')
		 //请求教学
		 this.getSysNews(2)
		 //请求市场
		 this.getMarket(0)
		 //请求类型
		 this.getHotTag()
		 //得到解决方案
		 this.getSolution(),
		 //回到顶部
		 backTop()
	},
	mounted() {
		new this.$wow().init();
	},
	methods: {
		getHotTag(){
			axios({
					url:global.apiURL+'portal/getHotTag',
					method:'get'
			}).then((result) => {
          this.hotTag=result.data.data.rows
					this.hotTag.splice(1,1)
      }).catch((err)=>{
			})
		},
		vHtml(s){
     return s.replace(/<[^>]+>/g, "");
		},
		btnSearch(){
      window.open(global.searchURL+this.key,'_blank')
		},
		getAnli(tagName){
			axios({
					url:global.apiURL+'portal/getPanoramaListByPaging?startPos=1&endPos=8&trade='+tagName+'&flag=home',
					method:'get'
			}).then((result) => {
				if(result.data.data.total>=1){
          this.ali=result.data.data.rows
					this.isShow=false
				}
				else{
          this.isShow=true
					this.ali=[]
				}	
					
      }).catch((err)=>{
			})
		},
		getSysNews(typeId){
			axios({
					url:global.apiURL+'portal/getSysNewsListByPaging?startPos=1&endPos=4&typeId='+typeId,
					method:'get'
			}).then((result) => {
				if(result.data.data.total>=1){
          this.sysNews=result.data.data.rows
					this.isSysNewsShow=false
				}
				else{
          this.isSysNewsShow=true
					this.sysNews=[]
				}	
					
      }).catch((err)=>{
			})
		},
			getMarket(panoType){
			axios({
					url:global.apiURL+'portal/getMarketPanoListByPaging?startPos=1&endPos=6&panoType='+panoType,
					method:'get'
			}).then((result) => {
				if(result.data.data.total>=1){
          this.market=result.data.data.rows
					this.isMarketShow=false
				}
				else{
          this.isMarketShow=true
					this.market=[]
				}	
					this.ins10=panoType
      }).catch((err)=>{
				
			})
		},
		getSolution(){
			axios({
					url:global.apiURL+'portal/getSolution?startPos=1&endPos=8&type=alls',
					method:'get'
			}).then((result) => {
          this.solutionMV=result.data.data.rows
      }).catch((err)=>{

			})
		},
		toPublish(flag){
			if(flag==='anli'){
				window.open(this.loginURL,'_blank')
			}
		},
		//切换样式
	 i6SpanClick (name,index) {
			this.ins=index
			this.getAnli(name)
		},
		i7SpanClick(index) {
			this.ins1 = index
		},
		i9SpanClick(index) {
			this.ins2=index
			this.getSysNews(this.ins2)
		}
	}
}
</script>
<style scoped>
	.VideoPlayer{
		overflow: hidden;
		object-fit: cover;
	}
	.VideoPlayer video{
		object-fit: cover;
	}
.i11la_div{
	height: 232px;
}
.i11la_div img.img{
	height: 100%;
}
.i6aimg_div{
	height: 232px;
}
.i6aimg_div img.img{
	height: 100%;
}
	
.video-js {
	width: 100%;
}

.video-js .vjs-duration,
.vjs-no-flex .vjs-duration {
	display: block;
}

.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
	display: block;
}

.video-js .vjs-time-control {
	display: block;
}

:deep(.video-js .vjs-control-bar) {
	display: none !important;
}
:deep(.mySwiper .swiper-button-prev, .mySwiper .swiper-button-next){
	width: 35px;
    height: 90px;
    background: none!important;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-top: -45px;
    font-size: 25px;
    color: #fff;
	left: 22%;
}

:deep(.mySwiper .swiper-button-next){
	width: 35px;
    height: 90px;
    background: none!important;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-top: -45px;
    font-size: 25px;
    color: #fff;
	right: 22%;
}
:deep(.mySwiper .swiper-button-prev::after){
	font-size: 14px;
	color: #ffffff;
	font-weight: bold;
}

:deep(.mySwiper .swiper-button-next::after){
	font-size: 14px;
	color: #ffffff;
	font-weight: bold;
}
@media only screen and (max-width:1199px) {
	.i11la_div{
		height: 210px;
	}	
	.i6aimg_div{
		height: 210px;
	}

}
@media only screen and (max-width:1100px) {
	.i11la_div{
		height: 182px;
	}
	.i6aimg_div{
		height: 182px;
	}
}
@media only screen and (max-width:768px) {
	.i11la_div{
		height: 210px;
	}
	.i6aimg_div{
		height: 210px;
	}
	
	:deep(.mySwiper .swiper-button-prev){
		width: 25px;
		height: 60px;
		left: 15px;
		margin-top: -30px;
	}
	:deep(.mySwiper .swiper-button-next){
		width: 25px;
		height: 60px;
		right:15px;
		margin-top: -30px;
	}
	.i3w_div .i3a{
		width: 48%;
	}
	.i9a_div{
		width: 48% !important;
	}
}
@media only screen and (max-width:576px) {
	.i3w_div .i3a{
		width: 100%;
	}
	.i11la_div{
		height: 110px;
	}
	.i6aimg_div{
		height: 110px;
	}
	.i3w_div .i3a{
		width: 100%;
	}
	.i9a_div{
		width: 100% !important;
	}
}
</style>
