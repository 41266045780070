<template>
	<div class="pay_tc">
		<el-dialog title="会员充值" v-model="detailVisiblePay" width="1200px" :close-on-click-modal="false">
			<div class="pay1 payborder disflex flex_center">
				<p class="text16 color666">请填写您的手机号</p>
				<div class="pay1r ml20">
					<input type="text"  placeholder="支付功能正在开发中...">
				</div>
			</div>
			<div class="pay2 payborder">
				<p class="text16 color666">请选择您要充值的金额</p>
				<div class="pay2w disflex flex_wrap textcenter">
					<div class="pay2a" @click="payNumberClick(1)" :class="{'numberActive':numberActive==1}">
						<h3 class="titleh3 coloryellow">￥299</h3>
						<p class="color999 mt_5">简单的文字描述</p>
					</div>
					<div class="pay2a" @click="payNumberClick(2)" :class="{'numberActive':numberActive==2}">
						<h3 class="titleh3 coloryellow">￥1999</h3>
						<p class="color999 mt_5">简单的文字描述</p>
					</div>
				</div>
			</div>
			<div class="pay3 payborder">
				<p class="text16 color666">请选择您的支付方式</p>
				<div class="pay3w disflex flex_wrap textcenter mt_15">
					<div class="pay3a" @click="payClick(1)" :class="{'payActive':payActive==1}">
						<img src="../../assets/images/wxico_03.png" /><p class="text16">微信支付</p>
					</div>
					<div class="pay3a" @click="payClick(2)" :class="{'payActive':payActive==2}">
						<img src="../../assets/images/zfbico_03.png" /><p class="text16">支付宝</p>
					</div>
					<div class="pay3r ml20 disflex flex_center text16">
						<span>应付金额：</span><h3 class="titleh3 coloryellow">￥299</h3>
					</div>
				</div>
			</div>
			<div class="pay4 textcenter mt_15">
				<img class="pay4img" width="150" src="../../assets/images/ewm1_03.png" />
				<p class="mt_10">使用微信扫一扫完成支付</p>
				<div class="txt text14 color666 mt_20">
					<p>如等待付款时间过长请手动刷新页面或换一种支付方式，</p>
					<p>支付完成后请添加微信客服：<i class="coloryellow">18682036600</i>，如有更多疑问请致电： <i class="coloryellow">18682036600</i></p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {ref} from 'vue'
	export default{
		name: "Pay",
		setup() {
			const detailVisiblePay = ref(false)
			const initPay = () =>{
				detailVisiblePay.value = true;
			}
			return{
				detailVisiblePay,
				initPay,
			}
		},
		data(){
			return{
				payActive:1,
				numberActive:1
			};
		},
		methods:{
			payClick(id){
				this.payActive = id
			},
			payNumberClick(id){
				this.numberActive = id
			},
		}
	}
</script>

<style>
	.pay_tc .el-overlay-dialog{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.pay_tc .el-dialog__headerbtn:hover i{
		color: #ffffff;
	}
	.pay_tc .el-dialog__body{
		padding: 35px;
	}
	.pay_tc .el-dialog__header{
		background: #f1511a;
		color: #fff;
		padding: 20px;
		margin-right:0;
	}
	.pay_tc .el-dialog__title{
		color: #fff;
	}
	.pay_tc .el-dialog__headerbtn .el-dialog__close{
		color: #fff;
		font-size: 30px;
	}
	.pay_tc .el-dialog{
		margin: 0 auto;
		max-width: 100%;
		height: 90%;
		overflow-y:auto ;
	}
	.payborder{
		padding: 25px 0;
		border-bottom: 1px solid #eee;
	}
	.pay1{
		padding-top: 0;
	}
	.pay1r input{
		width: 240px;
		height: 50px;
		padding: 0 15px;
		border: 1px solid #e6e6e6;
	}
	.pay2a{
		background: #f7f7f7;
		border: 1px solid #f7f7f7;
		border-radius: 6px;
		padding: 20px;
		width: 165px;
		margin-top: 15px;
		margin-right: 15px;
		cursor: pointer;
		transition: .25s;
	}
	.pay2a.numberActive,.pay3a.payActive{
		border: 1px solid #f1511a;
	}
	.pay3{
		border-bottom: 0;
	}
	.pay3a{
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #dddddd;
		width: 160px;
		height: 56px;
		cursor: pointer;
		margin-right: 15px;
	}
	.pay3a img{
		margin-right: 12px;
		display: block;
	}
	.pay4img{
		border: 1px solid #eee;
		display: block;
		margin: 0 auto;
	}
	@media only screen and (max-width:768px) {
		.pay_tc{
			max-width: 100% !important;
			width: 100% !important;
		}
		.pay_tc .el-dialog{
			width: 85% !important;
		}
		.pay_tc .el-dialog__headerbtn{
			top:10px;
			right: 10px;
			width: 34px;
			height: 34px;
		}
		.pay_tc .el-dialog__header{
			padding: 12px 15px;
		}
		.pay_tc .el-dialog__body{
			padding: 15px;
		}
		.payborder{
			padding: 15px 0;
		}
		.pay_tc .pay1{
			display: block;
			padding-top: 0;
		}
		.pay_tc .pay1r{
			margin-left: 0 !important;
		}
		.pay2a{
			width: 120px;
			padding: 15px 5px;
		}
		.pay2a p{
			font-size: 12px
		}
		.pay1r {
			margin-left: 0;
			margin-top: 10px;
		}
		.pay1r input{
			height: 40px;
			width: 100%;
		}
		.pay3a{
			width: 45%;
			margin-right: 5%;
		}
		.pay3r{
			margin-top: 10px;
			margin-left: 0;
		}
	}
</style>