<template>
	<div class="video_tc">
		<el-dialog title="视频播放" v-model="detailVisibleVideo" width="700px" :close-on-click-modal="false" :destroy-on-close="true">
			<div class="w100 textcenter colorfff">
				<video width="100%" class="video_url" :src="videoUrl" controls="controls" autoplay loop></video>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {ref} from 'vue'
	export default{
		name: "Video",
		setup() {
			let videoUrl = ref(); //接收视频地址
			const detailVisibleVideo = ref(false)
			const initVideo = (url) =>{
				videoUrl.value = url; //videoUrl 获取视频地址
				detailVisibleVideo.value = true;
			}
			return{
				videoUrl,
				detailVisibleVideo,
				initVideo,
			}
		},
		created() {
			
		},
		mounted(){
			
		},
		methods:{
		
		}
	}
</script>

<style>
	.video_tc .el-dialog__title{
		display: none;
	}
	.video_tc .el-overlay-dialog{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.video_tc .el-dialog__header{
		padding: 0;
	}
	.video_tc video{
		width: 100%;
	}
	.video_tc .el-dialog{
		background: #fff;
		background-size: cover;
		margin: 0 auto;
		margin-top: 0 !important;
	}
	.video_tc .el-dialog__body{
		padding: 0;
	}
	.video_tc .el-dialog__headerbtn .el-dialog__close{
		color: #fff !important;
		font-size: 30px;
	}
	.video_tc .el-dialog__headerbtn{
		z-index: 99;
	}
	
	@media only screen and (max-width:768px) {
		video_tc .el-dialog{
			width: 85% !important;
		}
		.video_tc .el-dialog__headerbtn{
			top:10px;
			right: 10px;
		}
	}
</style>