<template>
  <div class="app-container">
	<router-view></router-view>
  </div>
</template>

<script>
</script>

<style>
.app-container{
	overflow-x: hidden;
}
body{
	background: #fff;
}
.v-enter,.v-leave-to {
	opacity: 0;
	transition: all 0s;
}
.v-enter-to,.v-leave {
	opacity: 1;
	transition: all 0.5s;
}
</style>
