<template>
	<div>
		<!-- pc头部 -->
		<div class="head_pc">
			<div class="head">
				<div class="ht">
					<div class="wrap_1400 h100 disflex flex_lmr flex_center">
						<div class="logobox disflex flex_center">
							<a href="" class="logo"><img height="50" src="@/assets/images/logo.png" /></a>
							<div class="logotxt">
								<h4>大湾区VR全景领军者</h4>
								<h4>三维空间数字服务商：VR全景+视频+AI数字人：助力实体经济数字化转型</h4>
							</div>
						</div>
						<div class="htr">
							<div class="htrt disflex">
									<a href="https://editor.wormholesapp.com/user/login" target="_blank">会员充值</a>
								<router-link to="/vip">升级套餐</router-link>
								<a href="https://editor.wormholesapp.com/user/register?redirect_uri=https%3a%2f%2fwww.wormholesapp.com%2f" target="_blank">免费试用</a>
								<a href="https://www.bilibili.com/video/BV1m14y1N7iG/?spm_id_from=333.999.0.0&vd_source=b27057d406e6f3760ceea57a9d340903" target="_blank">VR教学</a>
							</div>
							<div class="htrb textright mt_15">
								<h3 class="text16 coloryellow">全国服务热线: 186 8203 6600</h3>
							</div>
						</div>
					</div>
				</div>
				<div class="hb">
					<div class="wrap_1400">
						<ul class="nav disflex flex_lmr text16">
							<li><router-link to="/" class="nava" :class="this.aName=='home'?'active':''">首页</router-link></li>
							<li><router-link to="/case" class="nava" :class="this.aName=='case'?'active':''">VR全景案例</router-link></li>
							<li><router-link to="/videoCenter" class="nava" :class="this.aName=='videoCenter'?'active':''">视频中心</router-link></li>
							<li><router-link to="/vip" class="nava" :class="this.aName=='vip'?'active':''">开通VIP</router-link></li>
							<li><router-link to="/join" class="nava" :class="this.aName=='join'?'active':''"> 我们的服务</router-link></li>
							<li><router-link to="/live" class="nava" :class="this.aName=='live'?'active':''">VR直播</router-link></li>
							<li><router-link to="/teaching" class="nava" :class="this.aName=='teaching'?'active':''">VR知识库</router-link></li>
							<li><router-link to="/services" class="nava" :class="this.aName=='services'?'active':''">软件服务</router-link></li>
							<li><router-link to="/about" class="nava" :class="this.aName=='about'?'active':''">关于虫洞</router-link></li>
							<li><a target="_blank" href="https://wormholesapp.com/#/panomap" class="nava">全景地图</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!-- wap头部 -->
		<div class="head_wap">
			<div class="whead disflex flex_lmr flex_center">
				<a class="wlogo" href="/"><img class="disblock h100" src="@/assets/images/logo.png" /></a>
				<i class="navicon" @click="clickToggleclass"><img src="@/assets/images/navicon.png" /></i>
			</div>
			<div class="whead_height"></div>
			<div class="wnav" :class="{'active':active}" ref="toggleclass">
				<div class="wnavbg" @click="clickRemoveclass"></div>
				<div class="wnavbox">
					<div class="wnavbox_hidden">
						<div class="wtree">
							<ul>
								<li class="p_item" :class="this.aName=='home'?'active':''"><router-link to="/" class="p_title">首页</router-link></li>
								<li class="p_item" :class="this.aName=='case'?'active':''"><router-link to="/case" class="p_title">VR全景案例</router-link></li>
								<li class="p_item" :class="this.aName=='videoCenter'?'active':''"><router-link to="/videoCenter" class="p_title">视频中心</router-link></li>
								<li class="p_item" :class="this.aName=='vip'?'active':''"><router-link to="/vip" class="p_title">开通VIP</router-link></li>
								<li class="p_item" :class="this.aName=='join'?'active':''"><router-link to="/join" class="p_title"> 我们的服务</router-link></li>
								<li class="p_item" :class="this.aName=='live'?'active':''"><router-link to="/live" class="p_title">VR直播</router-link></li>
								<li class="p_item" :class="this.aName=='teaching'?'active':''"><router-link to="/teaching" class="p_title">VR知识库</router-link></li>
								<li class="p_item" :class="this.aName=='services'?'active':''"><router-link to="/services" class="p_title">软件服务</router-link></li>
								<li class="p_item" :class="this.aName=='about'?'active':''"><router-link to="/about" class="p_title">关于虫洞</router-link></li>
								<li class="p_item"><a target="_blank" href="https://wormholesapp.com/#/panomap" class="p_title">全景地图</a></li>
							</ul>
						</div>
						<div class="htrt disflex flex_wrap">
							<a href="javascript:" @click="handlePay()">会员充值</a>
							<a href="http://cdviews.com/vip.html" target="_blank">升级套餐</a>
							<a href="https://editor.wormholesapp.com/user/login" target="_blank">全景发布</a>
							<a href="https://editor.wormholesapp.com/user/register?redirect_uri=https%3a%2f%2fwww.wormholesapp.com%2f" target="_blank">免费注册</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Pay ref="isShowPay"></Pay>
	</div>
</template>
<script>
	import { ref } from "vue";
	import Pay from '@/components/popup/Pay.vue'
		export default{
			name:'Header',
			components: {
				Pay
			},
			setup(){
				// 充值弹窗
				const isShowPay = ref(false)  //isShowPay 要和Form组件上的class名相同
				const handlePay = ()=> {
					isShowPay.value.initPay(); //initPay是子组件里的方法
				}
				return{
					isShowPay,
					handlePay
				}
			},
			data(){
				return{
					//导航高亮标识
					aName:'home',
					// 切换class
					active:false,
					// 充值弹窗
					VisiablePay:false
				};
			},
			props:['activeName'],
			mounted() {
				//导航高亮标识赋值
				this.aName = this.activeName;
			},
			methods:{
				// 点击切换class效果
				clickToggleclass(){
					this.active = !this.active
				},
				clickRemoveclass(){
					this.active = false
				},
				// // 充值弹窗
				// handlePay(){
				//   this.VisiablePay=true;
				//   this.$nextTick(()=>{
				// 	this.$refs.dialogPay.initPay();
				//   })
				// }
			}
		}
</script>

<style scoped>
.head{
	box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.ht{
	height: 120px;
}
.logotxt{
	border-left: 1px solid #e6e6e6;
	margin-left: 20px;
	padding-left: 20px;
}
.logotxt h4{
	font-size: 22px;
	color: #555;
}
.htrt a{
	display: inline-block;
	line-height: 28px;
	padding: 0 15px;
	border-radius: 4px;
	background: #23aeef;
	color: #fff;
	margin-left: 12px;
}
.htrt a:hover{
	opacity: .85;
}
.htrt a:nth-child(even){
	background: #23aeef;
}
.hb{
	background-image: linear-gradient(#6d6d6d, #4e4c4c);
	border-top: 1px solid #7a7a7a;
	border-bottom: 1px solid #7a7a7a;
}
.nav li{
	flex: 0;
	flex-grow: 1;
}
.nava{
	display: block;
	line-height: 55px;
	position: relative;
	text-align: center;
	color: #fff;
}
.nav li .nava:hover{
	color: #fff;
}
.nav li .nava.active{
		background-image: linear-gradient(#f80202, #d62404);
	color: rgb(209, 209, 209);
}

@media only screen and (max-width:1399px) {
	.logo img{
		height: 40px;
	}
	.logotxt h4{
		font-size: 18px;
	}
}
@media only screen and (min-width:768px) and (max-width:1199px) {
	.htrt a{
		font-size: 12px;
	}
}
@media only screen and (max-width:768px) {
	.htrt{
		justify-content: space-between;
		padding: 0 15px;
		margin-top: 5px;
	}
	.htrt a{
		text-align: center;
		width: 48%;
		margin: 0;
		margin-top: 10px;
	}
}
</style>