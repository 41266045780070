import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/Home.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' }
    },
    {
        path: '/about',
        name: 'about',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/about/About.vue')
    },
    {
        path: '/aboutCulture',
        name: 'aboutCulture',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/about/AboutCulture.vue')
    },
    {
        path: '/aboutCourse',
        name: 'aboutCourse',
        meta: { title: '发展历虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者程' },
        component: () =>
            import ('../views/about/AboutCourse.vue')
    },
    {
        path: '/aboutContact',
        name: 'aboutContact',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/about/AboutContact.vue')
    },
    {
        path: '/case',
        name: 'case',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/case/Case.vue')
    },
    {
        path: '/videoCenter',
        name: 'videoCenter',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/video/VideoCenter.vue')
    },
    {
        path: '/vip',
        name: 'vip',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/vip/Vip.vue')
    },
    {
        path: '/join',
        name: 'join',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/join/Join.vue')
    },
    {
        path: '/live',
        name: 'live',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/live/Live.vue')
    },
    {
        path: '/teaching',
        name: 'teaching',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/teaching/Teaching.vue')
    },
    {
        path: '/services',
        name: 'services',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/services/Services.vue')
    },
    {
        path: '/:solution*',
        name: 'solution',
        meta: { title: '虫洞VR全景官网-专业高效的3DVR全景展示拍摄制作云平台-大湾区VR全景领军者' },
        component: () =>
            import ('../views/solution/Solution.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: 'history',
    routes
})

//动态修改页面title
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title
    next()
})


export default router